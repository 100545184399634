import { EventAPIService } from "@/services/EventAPIService";

export default function(){
    const api = new EventAPIService(window.axios);

    const getUserEventSummary = async () => {
        try {
            const { success, message, events } = await api.get('events-data');

            if(success == 1){
                return events;
            }
            else{
                console.log(message);
                return null;
            }
        } catch (error) {
            console.log(error);
            return null;
        }
    }

    const getUserEvents = async () => {
        try {
            // await window.axios.get(`${pre_url}`);

            // const action = await window.axios.get(`${api_url}/events`, {
            //     headers:{
            //         'accept': 'application/json',
            //     }
            // });

            const { success, message, events } = await api.get('');

            if(success == 1){
                return events;
            }
            else{
                console.log(message);
                return null;
            }
        } catch (error) {
            console.log(error);
        }
    }

    const getEvent = async (id) => {
        try {
            const { success, message, event } = await api.get(id);

            if(success == 1){
                return event;
            }
            else{
                console.log(message);
                return null;
            }
        } catch (error) {
            console.log(error);
        }
    }

    const createEvent = async (data) => {
        try {
            const { success, message, event } = await api.post(data);

            console.log(message);
            
            if(success == 1) {
                return [true, event, message];
            }
            else{
                return [false, event, message];
            }
        } catch (error) {
            console.log(error);
            return false;
        }
    }

    const updateEvent = async (id, data) => {
        try {
            const { success, message, event } = await api.put(id, data);

            console.log(message);
            
            if(success == 1) {
                return [true, event, message];
            }
            else{
                return [false, event, message];
            }
        } catch (error) {
            console.log(error);
            return [false, ''];
        }
    }

    const deleteEvent = async (id) => {
        try {
            const { success, message } = await api.delete(id);

            console.log(message);
            
            if(success == 1) {
                return [true, message];
            }
            else{
                return [false, message];
            }
        } catch (error) {
            console.log(error);
            return [false, ''];
        }
    }

    return {
        getUserEventSummary, getUserEvents, createEvent, getEvent, updateEvent, deleteEvent,
    }
}