<template>
    <div :class="classes">
        <p v-html="text">
        </p>
    </div>
</template>

<script>
export default {
    props:{
        classes: {
            required: false,
            type: String,
        },
        text: {
            required: false,
            type: String,
        }
    }
}
</script>

<style>
@import '../../assets/css/alert.css';
</style>