export const userStore = {
    state: () => {
        return {
            user: {
                id: null,
                fullname: 'Loading',
                company: 'Loading'
            }
        }
    },
    mutations: {
        setUser(state, user) {
            state.user = user;
        }
    }
};