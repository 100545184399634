<template>
    <div id="main-menu">
            <div id="main-nav">
                <nav>
                    <a href="" class="side-icon" @click.prevent="toggleMenu"><i :class="['fas', menuOpen ? 'fa-times': 'fa-bars']"></i></a>
                    <a href="/dashboard"><i class="fas fa-home"></i>home</a>
                    <a href="/my-events" v-if="!isApp"><i class="fas fa-calendar"></i>events</a>
                    <router-link v-else to="/my-events"><i class="fas fa-calendar"></i>events</router-link>
                    <a href="/reports"><i class="fas fa-poll"></i>reports</a>
                </nav>
            </div>
            <div id="user-menu">
                <a :href="logout_url" @click="clearStorage()"><i class="fas fa-power-off"></i>Logout</a>
            </div>
        </div>
</template>

<script>
export default {
    name: "MenuComponent",
    inject:['base_url', 'pre_url'],
    data(){
        return {
            logout_url: `${this.base_url}/logout`,
            menuOpen: false,
        }
    },
    methods: {
        toggleMenu(){
            this.menuOpen = !this.menuOpen;
            this.$store.commit('setMenu', this.menuOpen);
        },
        async clearStorage(){
            localStorage.removeItem('logged');
            //await this.logout();
        },
        // async logout(){
        //     try {
        //         await window.axios.get(`${this.pre_url}`);
        //         const logout = await window.axios.get(`${this.base_url}/logout`);

        //         return logout.data.success;
        //     } catch (error) {
        //         console.log(error);
        //         return false;
        //     }
        // }
    },
    computed: {
        isApp(){
            let url = window.location.href;
            return url.indexOf('events',9) == -1 ? 0 : 1;
        }
    }
}
</script>