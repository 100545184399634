const axios = require('axios');

axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
axios.defaults.withCredentials = true;

import { EventAPIService } from "@/services/EventAPIService";
const api = new EventAPIService(axios);

export const eventStore = {
    state: () => {
        return {
            events: [],
            positions: [],
            candidates: [],
        }
    },
    mutations: {
        setEvents(state, events) {
            state.events = events;
        },
        addEvent(state, event) {
            state.events.unshift(event);
        },
        updateEvent(state, event) {
            let idx = state.events.findIndex(({id}) => id == event.id);
            state.events.splice(idx, 1, event);
        },
        cancelEvent(state, eventId) {
            let idx = state.events.findIndex(({id}) => id == eventId);
            //(state.events[idx]).status = 'CANCELLED';
            state.events.splice(idx, 1);
        },
        setPositions(state, positions){
            state.positions = positions;
        },
        addPosition(state, position){
            state.positions.push(position);
        },
        setCandidates(state, candidates){
            state.candidates = candidates;
        },
        addCandidate(state, candidate){
            state.candidates.push(candidate);
        },
        removePosition(state, id){
            state.positions = state.positions.filter((position) => position.id !== id);
        },
        removeCandidate(state, id){
            state.candidates = state.candidates.filter((candidate) => candidate.id !== id);
        },
    },
    getters: {
        getLatestEvent(state){
            return state.events[0];
        },
        getEventbyId:(state) => (eventId) => {
            return state.events.find(({id}) => id == eventId);
        },
        getReportableEvents(state) {
            return state.events.filter( ({status}) => {
                return status == 'ACTIVE' || status == 'CLOSED';
            });
        },
        getLiveEvents(state){
            return state.events.filter( ({status}) => {
                return status == 'ACTIVE';
            });
        },
        getPositionCandidates: (state) => (id) => {
            return state.candidates.filter(({ position_id }) => parseInt(position_id) == parseInt(id));
        }
    },
    actions: {
        async getEvents ({commit}) {
            try {    
                const { success, message, events } = await api.get('');
    
                if(success == 1){
                    commit('setEvents', events);
                }
                else{
                    console.log(message);
                }
            } catch (error) {
                console.log(error);
            }
        }
    }
}