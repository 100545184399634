<template>
    <div id="main-panel">
        <menu-component/>
        <div class="main-content">
            <!--it's own css but layed out by theme.css-->
            <slot></slot>
        </div>
    </div>
</template>

<script>
import MenuComponent from './MenuComponent.vue';
import useUser from '@/composables/useUser.js';
import { useStore } from 'vuex';

export default {
    components: { MenuComponent, },
    setup(){
        const store = useStore();
        const { verifyLogin } = useUser();

        const create = async () => {
            const [loggedIn, user] = await verifyLogin();
            if(!loggedIn){
                localStorage.removeItem('logged');
                window.location.replace('/');
            }
            else{
                localStorage.setItem('logged', JSON.stringify(true));
            }

            store.commit('setUser', user);
        }

        create();
    },
}
</script>