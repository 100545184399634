export const uiStore = {
    state: () => {
        return {
            menu: {
                open: false,
            }
        }
    },
    mutations: {
        setMenu(state, val) {
            state.menu.open = val;
        }
    }
};