import { inject } from "vue";

export default function (){
    const base_url = inject('base_url');
    const api_url = inject('api_url');
    const pre_url = inject('pre_url');

    const verifyLogin = async () => {
        try {
            await window.axios.get(`${pre_url}`);
            const verifyAction = await window.axios.get(`${api_url}/auth-check`);

            if(verifyAction.data.success == 1){
                return [true, verifyAction.data.user];
            }
            else{
                console.log(verifyAction.data.message);

                return [false, null];
            }
        } catch (error) {
            console.log(error);
            return [false, null];
        }
    }

    const logout = async () => {
        try {
            await window.axios.get(`${pre_url}`);
            const logout = await window.axios.get(`${base_url}/logout`);

            return logout.data.success;
        } catch (error) {
            console.log(error);
            return false;
        }
    }

    return {
        verifyLogin, logout
    }
}