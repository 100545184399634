<template>
    <div id="side-panel" :class="['',{show: menuOpen}]">
        <div class="user-info">
            <div class="logo">
                <i class="fas fa-user"></i>
            </div>
            <div class="user-info-content">
                <h4>{{ user.fullname }}</h4>
                <span>{{ user.company }}</span>
            </div>
        </div>
        <div id="side-menu">
            <ul>
                <li v-if="!isApp">
                    <a href="/my-events"><i class="fas fa-calendar"></i>Events</a>
                </li>
                <li v-else>
                    <router-link to="/my-events"><i class="fas fa-calendar"></i>Events</router-link>
                </li>
                <li v-if="!isApp">
                    <a href="/my-events/voters"><i class="fas fa-users"></i>Voters</a>
                </li>
                <li v-else>
                    <router-link to="/my-events/voters"><i class="fas fa-users"></i>Voters</router-link>
                </li>
                <li>
                    <a href="/reports"><i class="fas fa-poll"></i>reports</a>
                </li>
                <li>
                    <a href="#"><i class="fas fa-cog"></i>settings</a>
                </li>
                <li>
                    <a href="#"><i class="fas fa-star" style="color: yellow;"></i>upgrade</a>
                </li>
            </ul>
        </div>
        <div class="side-footer">
            Jubatus Technologies &copy; {{ new Date().getFullYear() }}
        </div>
    </div>
</template>

<script>
export default {
    computed: {
        user(){
            return this.$store.state.userState.user;
        },
        menuOpen(){
            return this.$store.state.uiState.menu.open;
        },
        isApp(){
            let url = window.location.href;
            return url.indexOf('events',9) == -1 ? 0 : 1;
        }
    }
}
</script>

<style>
.show {
    visibility: visible !important;
}
</style>