<template>
    <footer>
        <div class="section">
            <div id="links-container">
                <div class="links">
                    <h5>Links</h5>
                    <ul>
                        <li>
                            <a href="https://www.jubatus.co.ke">Jubatus.co.ke</a>
                        </li>
                        <li>
                            <a href="">voting.jubatus.co.ke</a>
                        </li>
                    </ul>
                </div>
                <div class="links">
                    <h5>Contacts</h5>
                    <ul>
                        <li>
                            <a href="mailto:info@jubatus.co.ke">info@jubatus.co.ke</a>
                        </li>
                        <li>
                            <a href="tel:+254728403413">0728 403 413</a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="copyright">
                <p>Powered by Jubatus Technologies - &copy; {{ year }}. All rights reserved.</p>
            </div>
        </div>
    </footer>
</template>

<script>
export default {
    name: "FooterComponent",
    data(){
        return {
            year: new Date().getFullYear(),
        }
    }
}
</script>