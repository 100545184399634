export class EventAPIService {
    httpInstance;
    baseUrl = process.env.VUE_APP_BASE_URL;
    preUrl = process.env.VUE_APP_PRE_URL;
    apiUrl = process.env.VUE_APP_API_URL;
    resource = 'events';

    constructor(httpInstance){
        this.httpInstance = httpInstance;
    }

    async get(id){
        try {
            //if(!id) throw Error("resource id is not provided.");

            await this.httpInstance(`${this.preUrl}`);

            const response = await this.httpInstance.get(this.getUrl(id));

            return response.data;
        } catch (error) {
            this.handleErrors(error);
        }
    }

    getUrl(id = ""){
        return `${this.apiUrl}/${this.resource}/${id}`;
    }

    async post(data = {}) {
        try {
            await this.httpInstance(`${this.preUrl}`);

            const response = await this.httpInstance.post(this.getCreateURL(), data, {
                headers:{
                    'accept':'application/json',
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            });
            
            return response.data;
        } catch (error) {
            this.handleErrors(error);
            return false;
        }
    }

    async put(id, data={}){
        try {
            if(!id) throw Error("id not provided");

            await this.httpInstance(`${this.preUrl}`);

            const response = await this.httpInstance.post(this.getUrl(id), data, {
                headers:{
                    'accept':'application/json',
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            });

            return response.data;
        } catch (error) {
            this.handleErrors(error);
        }
    }

    async delete(id) {
        if(!id) throw Error("id not provided");

        //await this.httpInstance(`${this.preUrl}`);
        try {
            const response = await this.httpInstance.delete(this.getUrl(id), {
                headers:{
                    'accept':'application/json',
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            });
            
            return response.data;
        } catch (error) {
            this.handleErrors(error);
        }
    }

    getCreateURL(){
        return `${this.apiUrl}/${this.resource}`;
    }

    handleErrors(err){
        console.log({message: "Error: ", err: err.message});
    }
}